import React from 'react';

const DemoAbout = () => {
    return (
        <div className='page-container-root'>
            <h1>About</h1>
        </div>
    );
}

export default DemoAbout;
import React from 'react';
import '../Page.css';

const Services = () => {
    return (
        <div className='page-container-root'>
            <div className='page-container'>
 
            </div>
        </div>
    );
}

export default Services;